import gql from "graphql-tag";

export const contactUs = gql`
  mutation contactUs($input: ContactInput!) {
    contactUs(input: $input)
  }
`;

export const join = gql`
  mutation join($input: JoinInput!) {
    join(input: $input)
  }
`;

export const createProfile = gql`
  mutation createProfile($input: ProfileInput!) {
    createProfile(input: $input) {
      availableFrom
      canContact
      createdAt
      summary
      education {
        educationId
        startYear
        university
        degree
        field
        endYear
      }
    }
  }
`;

export const updateProfile = gql`
  mutation updateProfile($input: ProfileInput!) {
    updateProfile(input: $input) {
      firstName
      lastName
      title
      availableFrom
      canContact
    }
  }
`;

export const saveProfileSummary = gql`
  mutation saveProfileSummary($input: String!) {
    saveProfileSummary(input: $input) {
      summary
    }
  }
`;

export const createEducation = gql`
  mutation createEducation($input: EducationInput!) {
    createEducation(input: $input) {
      educationId
      university
      degree
      field
      startYear
      endYear
    }
  }
`;

export const saveEducation = gql`
  mutation saveEducation($input: EducationInput!) {
    saveEducation(input: $input) {
      educationId
      university
      degree
      field
      startYear
      endYear
    }
  }
`;

export const deleteEducation = gql`
  mutation deleteEducation($input: String!) {
    deleteEducation(input: $input) {
      educationId
    }
  }
`;
